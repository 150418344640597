import React from 'react'
import Another from '../utils/image/current.png'
import { Outlet, useLocation, Link } from 'react-router-dom'
import Event from './Event'
import { GalaNite, CEO, Gallery6, Supporters, Awardee } from '../utils/Datas'
import Register from '../Home/Register'

function Gala() {
  const location = useLocation()
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (

    <div>


      <Outlet />
     {location.pathname === "/gala" && 
   ( <div>
       <div className='text-center text-4xl font-bold py-10 bg-[#D35515]'>
        <p>Gala Nite</p>
      </div>


      <div className='text-center text-black py-20'>
    <p className='text-4xl font-semibold mb-5'>Current Events</p>
    <p>Tickets are limited, so boook now to secure your spot on board</p>
    </div>

    <section className='px-5 lg:px-20'>

        <section className='flex justify-between flex-wrap-reverse lg:flex-nowrap py-10'>
        <div className='lg:mt-5 mt-10 lg:w-[40%]'>
        <h1 className='text-4xl text-black'>14th Annual AFFI Awards  Banquet Yatch Cruise 2024</h1>
        <p className='text-[#5E6068] py-5'>Experience a distinctive setting that combines business and leisure with global personalities as well as networking etc.</p>
        <div className='flex flex-wrap lg:flex-nowrap gap-10 mt-5'>
        <a className='px-8 py-3 bg-[#D35515] font-bold rounded-full' href='https://www.eventbrite.com/e/14th-annual-affi-awards-dinner-banquet-yatch-cruise-2024-tickets-916503886797?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=ebdsshcopyurl' >Buy Ticket</a>
        <Link to="event" ><button className='px-6 py-3 rounded-full text-black border-2'>Event Details</button></Link>
        </div>
        </div>

        <div className=' shadow-2xl lg:w-[45%]'>
        <img src={Another} alt='hja' />
        </div>
        
        
        </section> 
    </section>



    <section className='px-5 lg:px-20 py-14 text-black'>
    <div className='text-center'>
    <p className='text-4xl font-semibold mb-5'>Awardee</p>
    <p>Tickets are limited, so boook now to secure your spot on board</p>
    </div>


    <section className='flex flex-wrap justify-center lg:justify-between '>

   {Awardee.map((support) => ( <div className='flex-col flex lg:w-[32%] justify-center items-center mt-10' key={support.id}>
    <img src={support.img} alt='jhhb'/>

    <p className='text-2xl mt-5'>{support.name}</p>
    <button className='px-6 py-3 mt-2 border rounded-full'>Biography</button>
    </div>))}
    
    </section>
    
    
    </section>
    <Register />
      
    </div>

)}


    </div>
  )
}

export default Gala
