import React, { useState, useEffect } from 'react';
import { GalleryPics, GalleryVideos, Supporters } from '../utils/Datas';
import {ClipLoader }from 'react-spinners'

function Gallery() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [activeLink, setActiveLink] = useState(0);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const choose = ["Supporters", "Past events"];

  useEffect(() => {
    const loadContent = async () => {
      setIsLoading(true);

      if (activeLink === 0) {
        await new Promise(resolve => setTimeout(resolve, 500)); 
        setImages(GalleryPics);
      } else {
        // Simulating an async loading of videos
        await new Promise(resolve => setTimeout(resolve, 500)); 
        setVideos(GalleryVideos);
      }

      setIsLoading(false);
    };

    loadContent();
  }, [activeLink]);

  return (
    <div>
      <div className='text-center text-4xl font-bold py-10 bg-[#D35515]'>
        <p>Gallery</p>
      </div>

      <div className='text-center flex justify-center gap-10 text-black mt-10'>
        {choose.map((ch, index) => (
          <p key={index}>
            <a
              href='#'
              onClick={() => setActiveLink(index)}
              className={activeLink === index ? 'border-b-2 border-[#D35515] pb-2 text-[#D35515] font-bold' : 'text-black font-bold'}
            >
              {ch}
            </a>
          </p>
        ))}
      </div>

      <section className='px-5 pb-16 lg:px-20'>
        <section className='flex justify-center flex-wrap gap-10 lg:gap-5 mt-10'>
          {isLoading ? (
            <p> <ClipLoader /> </p>
          ) : (
            <>
              {activeLink === 0 &&
                <section className='flex flex-wrap justify-center lg:justify-evenly '>

                {Supporters.map((support) => ( <div className='flex-col flex lg:w-[32%] justify-center items-center mt-10' key={support.id}>
                 <img src={support.img} alt='jhhb'/>
             
                 <p className='text-2xl mt-5 text-black text-center'>{support.name}</p>
                 <p className='mt-1 text-[#7D808B]'>{support.title}</p>
                 </div>))}
                 
                 </section>
                 
                  }
              {activeLink === 1 &&
               GalleryPics.map((gallery, index) => (
                <div key={index} className='lg:w-[30%]'>
                <img src={gallery} alt={gallery} loading='lazy' />
                </div>
               ))
                }
            </>
          )}
        </section>
      </section>
    </div>
  );
}

export default Gallery;

