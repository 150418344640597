import Logo from './image/logo.png'
import Hero from './image/heros.png'
import Gallery1 from './image/newgallery1.png'
import Gallery2 from './image/newgallery2.png'
import Gallery3 from './image/newgallery3.png'
import Gallery4 from './image/newgallery4.png'
import Gallery5 from './image/newgallery5.png'
import Gallery6 from './image/newgallery6.png'
import Gallery7 from './image/gallery7.png'
import Gallery8 from './image/gallery8.png'
import Gallery9 from './image/gallery9.png'
import Gallery10 from './image/gallery10.png'
import Gallery11 from './image/gallery11.png'
import Gallery12 from './image/gallery12.png'
import Gallery13 from './image/gallery13.png'
import Gallery14 from './image/gallery14.png'
import Gallery15 from './image/gallery15.png'
import Promote from './image/promote.png'
import Objective from './image/obj.png'
import HandShake from './image/mdi_handshake-outline.png'
import Education from './image/edu.png'
import Health from './image/mage_heart-health.png'
import Community from './image/community.png'
import Values from './image/value.png'
import Program from './image/programs.png'
import Upcoming from './image/articles.png'
import Upcoming2 from './image/article1.png'
import Upcoming3 from './image/article2.png'
import Mission from './image/credit.png'
import CEO from './image/Ceo founder.png'
import Parker from './image/parker.png'
import Louis from './image/fara.png'
import Pastor from './image/pastor.png'
import Yuvete from './image/yuvete.png'
import Hong from './image/hong.png'
import Oba from './image/obasnjo.png'
import Success from './image/successful 1.png'
import Support from './image/support.png'
import Check from './image/check.png'
import HisExcellency from './image/hisExcellency.png'
import HerExcellency from './image/herExcellency.png'
import Anthony from './image/anthony.png'
import Jessica from './image/jessica.png'
import Anna from './image/anna.png'
import { FaCalendar, FaLocationArrow, FaPhone } from 'react-icons/fa'



export const UpcomingGen = [
    {
        id: 1, 
        img: Upcoming,
        title: "Arit Fuller Foundation Has Donated Books To Boost Educati..",
        summ: "Arit fuller foundation has been donating books for the purpose of promoting literacy. Books provide access to knowledge, educa..."
    },
    {
        id: 2, 
        img: Upcoming2,
        title: "Akwa Ibom First Lady Lauds AFFI as an NGO",
        summ: "The wife of the Govenor of Akwa ibom State, Pastor (Mrs) Patience Umo Eno has lauded the Rit Fuller-Harris Foundation, a ... "
    },
    {
        id: 3, 
        img: Upcoming3,
        title: "Why Arit Fuller Promotes Award Ceremony and Boat Cruise",
        summ: "Experience a distinctive setting that combines business and leisure with global personalities as well as networking etc."
    }
]

export const Supporters = [
    {
        id: 1, 
        img: Parker,
        name: "Senator Kelvin Parker",
        title: "New York Senator"
    },
    {
        id: 2, 
        img: Louis,
        name: "Fara Loius",
        title: "New York City Council"
    },
    {
        id: 3, 
        img: Pastor,
        name: "Pastor Babajide Balogun",
        title: "Director, Arit Fuller Foundation"
    },
    {
        id: 4, 
        img: Yuvete,
        name: "Yvette D. Clarke",
        title: "Congress Woman 9th District of New York"
    },
    {
        id: 5, 
        img: Hong,
        name: "Dr. Hong Tao Tze",
        title: "President, Federation of World Peace"
    },
    {
        id: 6, 
        img: Oba,
        name: "H.E. Bola Obasanjo",
        title: "Special Guest of Honour"
    },
    {
        id: 7, 
        img: HisExcellency,
        name: "His Excellency Prince Bassey Edet Otu", 
        title: "Govenor of Cross River State"
    },
    {
        id: 8, 
        img: HerExcellency,
        name: "Her Excellency Rev Mrs. Eyoanwan Bassey Otu", 
        title: "Wife Of Govenor of Cross River State"
    },

]

export const Awardee = [
    {
        id: 1, 
        img: Parker,
        name: "Senator Kelvin Parker"
    },
    {
        id: 2, 
        img: Louis,
        name: "Fara Loius"
    },
    {
        id: 3, 
        img: Pastor,
        name: "Pastor Babajide Balogun"
    },
    {
        id: 4, 
        img: Yuvete,
        name: "Yvette D. Clarke"
    },
    {
        id: 5, 
        img: Hong,
        name: "Dr. Hong Tao Tze"
    },
    {
        id: 6, 
        img: Oba,
        name: "H.E. Bola Obasanjo"
    },   
]


export const GalaNite = [
    {
        id: 1,
        icon: <FaCalendar />,
        title: "Date & Time", 
        detail: [
            "Saturday September 21st, 2024",
            "5pm to 9pm", 
            "Boarding starts by 4:30pm"
        ]
    },

    {
        id: 2,
        icon: <FaLocationArrow />,
        title: "Location",
        detail: [
            "Pier 36 NYC 299 South Street", 
            "New York, NY 10002 United States"
        ]
    }, 

    {
        id: 3, 
        icon: <FaPhone />,
        title: "Contact",
        detail: [
            "800 228 9290",
            "+234 803 351 4961",
            "+1 (347) 526 3884"
        ]
    }
]


export const lastProgram = [
    {
        id: 1,
        title: "Scholarships",
        summ: "Scholarship programs for students"
    },
    {
        id: 2,
        title: "Healthcare",
        summ: "Healthcare outreach and medical missions"
    },
    {
        id: 3,
        title: "Entrepreneurship",
        summ: "Entrepreneurship training and microfinance initiatives"
    },
    {
        id: 4,
        title: "Development",
        summ: "Community development projects"
    },
]


export {
    Logo, Hero, CEO, Gallery6, Values, Mission, Objective, Program, Promote, Success,
    Support, Check
}

export const obj = [
    {
        id: 1,
        img: Education,
        title: "Education",
        summ: "Provide Access to quality education for underprivileged children and youth."
    },
    {
        id: 2,
        img: Health,
        title: "Healthcare",
        summ: "Support healthcare initiatives that promotes wellness and disease prevention."
    },
    {
        id: 3,
        img: Community,
        title: "Empowerment",
        summ: "Empower individuals & communities via economic development programs"
    },
    {
        id: 4,
        img: HandShake,
        title: "Partnerships",
        summ: "Foster partnerships and collaborations to amplify our impact."
    }
    
]

export const GalleryPics  = [
    Gallery1,
    Gallery2,
    Gallery3,
    Gallery4,
    Gallery5,
    Gallery6,
]

export const GalleryVideos = [
    "https://www.youtube.com/embed/hHvmIXmoMaA?si=r36NDxXwiHXy25io",
    "https://www.youtube.com/embed/hnaVgreCEWM?si=T9vqsSduhDb5T0UC",
    "https://www.youtube.com/embed/v0W_lA7rW0Y?si=fMbUtxxZ4tHQn-PF",
    "https://www.youtube.com/embed/Di9mItwaQ3Y?si=E66h-uTgxaoFNfcb",
    "https://www.youtube.com/embed/c8lW29OZA5k?si=TN6mkZNBTcOsVWR-",
    "https://www.youtube.com/embed/3mYSlmidImo?si=yrkA3nr849ImIZ2_",
]

export const Nav = [
    {
        id: 1, 
        navi: "Home", 
        link: "/"
    },
    {
        id: 2, 
        navi: "Gallery", 
        link: "/gallery"
    } ,
    {
        id: 3, 
        navi: "Gala Nite", 
        link: "/gala"
    },  
]



export const testimony = [
    {
        id: 1,
        img: Anthony,
        name: "Anthony Moore",
        role: "Sponsor",
        test: "Their commitment to education programs is inspiring. As a donor, i've seen how they efficiently channel resources to create meaningful oppourtunities. it's incredibleto witness how they're shaping abrighter future for so many individuals."
    },
    {
        id: 2,
        img: Jessica,
        name: "Jessica Harrison",
        role: "Voluteer",
        test: "Volunteering with AFFI has been an incredibly rewarding experience. Their dedication to empowering commuities through education is unmatched. Seeing the direct impact on children's lives has been heartwarming."
    },
    {
        id: 3,
        img: Anna,
        name: "Anna Jacobs",
        role: "Participants",
        test: "AFFI doesn't just provide aid, they instill hope. Their approach to supporting educatiion is hostilic and impactful. i've witnessed firsthand the dedication and passion of their team, making a real differnce in the lives of those they teach."
    }
]